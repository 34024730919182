
@import 'localFonts/customFont.css';




/*under row_collector/<div>*/
button.logout_button {
    right: 0;
    position: fixed;
    right: 4px;
    top: 1.35vh;

    background-color: transparent;
    border: 0px;
    transition: transform .2s;
}

.logout_button i {
    z-index: 10

}
.logout_button:hover {
    cursor: pointer;
    transform: scale(1.5);

}

.logout_button:hover i{
    color:red;

}


.clientCard_btn_collection {
	margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}



	/*these buttons have several names and i added one (this one) but can't figure out how the others references affects the buttons.*/
	.clientCard_btn {
		/*width: 40%;*/
		border-radius: 2px;
    	letter-spacing: 1.5px;
    	margin: 5px;
   	 	color: black !important;
    	font-family: Calibri;
    	text-transform: uppercase;
    	background-color: white;
	}

	a.btn:hover {
    	background: black;
   	 	color:white !important;
	} 