/* *, *:before, *:after {
	box-sizing: border-box;
  } */

  @import 'localFonts/customFont.css';


  #root {
    font-family: "Montserrat", sans-serif;
    color: #7f7e80;
    height: 100vh;
    width: 100vw;
    -webkit-font-smoothing: antialiased;
  }
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
  
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Poppins:wght@300;500&display=swap");
  
  @import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap");


#App{
  height: 100%;
  width: 100%;
  overflow: hidden;
}


#pdfButtonContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
#pdfGeneratorMainContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

#subButtonContainer{
  display: flex;
  flex-direction: row;
}

#pdfInputButtons{
  display: flex;
}

.pdfPageButton{
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  border-radius: 5px;
  border: 2px solid black;
  font-size: 1.2em;
  font-weight: 500;
  height: fit-content;
}
.pdfPageButton:hover{
  cursor: pointer;
}

.admin-groups{
  width: 90%;
  height: 100vh;
  overflow-y: scroll;
}

#adminGroupsRowContainer{
  width: 90%;
  display: flex;
  flex-direction: row;
}

#adminGroupsTablesContainer{
  width: 90%;
}

#benchmarksMainContainer{
  width: 90%;
  height: 100vh;
  overflow-y: auto;
}

#filesMainContainer{
  width: 90%;
  height: 100vh;
  overflow-y: auto;
}

.main_col_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
/*under main_container*/
/*topnavbar padding-left should be the same size as sidemenu width.*/
#topnavbar {
  /* right: 0; */
  /* top: 0; */
  /* left: 100px; */
  /* z-index: 1000; */
  /* position: fixed; */
  width: 100%;
  background: rgb(246, 246, 246);
  /* height: 50px; */
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid gainsboro;
}

.highcharts-button-pressed {
  background: red !important;
  margin: 10px !important;
}
/*under topnavbar*/
.topnav_element {
  background: rgb(246, 246, 246);
  display: block;
  margin-right: 140px;
}

@media screen and (max-width: 1650px) {
  .topnav_element {
    background: rgb(246, 246, 246);
    display: block;
    margin-right: 70px;
  }
}

@media screen and (max-width: 1230px) {
  .topnav_element {
    background: rgb(246, 246, 246);
    display: block;
    margin-right: 20px;
  }
}

@media screen and (max-width: 1060px) {
  .topnav_element {
    background: rgb(246, 246, 246);
    display: block;
    margin-right: 20px;
  }
  .topnav_element span.string{
    font-size: 10px !important;
    letter-spacing: 1px !important;

  }
  .topnav_element span.numb{
    font-size: 10px !important;
    letter-spacing: 1px !important;

  }
}

@media screen and (max-width: 900px) {
  #topnavbar {
    justify-content: flex-start !important;
    padding-left: 20px;
  }

}


@media screen and (max-width: 810px) {
  .topnav_element {
    background: rgb(246, 246, 246);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25px;
    margin-left: 15px;
  }
  .topnav_element span.string{
    font-size: 10px !important;
    letter-spacing: 1px !important;

  }
  .topnav_element span.numb{
    font-size: 10px !important;
    letter-spacing: 0px !important;
    white-space: nowrap;

  }

  #topnavbar {
    justify-content: center !important;
    padding-left: 20px;
  }

}


/*under topnav_element*/
.topnav_element span.string {
  font-size: 13px;
  letter-spacing: 2px;
  color: gray;
  line-height: 0.9;
  text-transform: uppercase;
  font-family: "Calibri Light";
}

/*under topnav_element*/
.topnav_element span.numb {
  color: #000;
  font-weight: 600;
  font-size: 12px;
  line-height: 1px;
  letter-spacing: 2px;
  font-family: "Calibri Light";
}

@media screen and (max-width: 1500px) {
  .topnav_element span {
    font-size: 11px !important;
  }
}

/*why is !important needed here?*/
@media screen and (max-width: 700px) {
  .topnav_element span {
    font-size: 10px !important;
  }
}

.btn {
  margin: auto;
  transition: all 0.4s ease-in-out;
}

picture {
  opacity: 0.5;
}

.btn {
  height: 3vh !important;
  line-height: 1;
}

button:hover {
 /* transform: scale(1.2);
  color: #000;
*/
}

/*under main_container*/
.workspace {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 25px;
  flex-grow: 1;
  background: rgb(246, 246, 246);
  border-radius: 5px;
  /* width: 100%; */
  /* height: 100%; */
  gap: 25px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
}

#adminUserOperations{
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}



/* OUTDATED  */
/*liger under #workspace*/
#row_container {
  height: 100%;
  display: block;
}

/* OUTDATED  */
/*liger under row container, id = r1*/
.row_analysator {
  padding-bottom: 2vh;
  padding-right: 1vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 50%;
}


/* make every box.-module place in one column. "mobilViev"*/
/* @media screen and (min-width: 1500px) {
  .workspace {
    gap: 2rem 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 4fr));
  }
} */
@media screen and (max-width: 1000px) {
  .workspace {
    margin: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .workspace {
    margin: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
}
@media screen and (max-width: 800px) {
  .workspace {
    margin: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .workspace {
    margin: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
  .box-module {
    margin-bottom: 20px;
  }
}

@media (max-width: 425px) {
  .workspace {
    margin: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
  .box-module {
    margin-bottom: 20px;
  }
}


.popUpinfo {
  position: fixed;
  background-color: whitesmoke;
  border-radius: 5px;
  border: black 1px solid;
  /* visibility: ; */
  z-index: 5;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  height: fit-content;
  top: 0;
  left: 0;
}