.dashboardLanding {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.dashboardHomeContainer {
  background-color: white;
  flex: 100;
  display: flex;
}

.report-style-class {
  width: 100%;
  height: 100%;
}

.NoReportError {
  margin: auto;
}

.Export-data-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Export-data-box {
  border: 1px solid rgb(129, 129, 129);
  border-radius: 7px;
  background-color: rgb(255, 255, 255);
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
}

.export-data-box-header {
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
  width: 400px;
  padding: 10px;
  max-width: 500px;
}

.Export-data-button {
  padding: 10px;
  border: 1px solid rgb(129, 129, 129);
  border-radius: 7px;
  background-color: rgb(236, 236, 236);
  margin: 0;
  width: 40%;
  text-align: center;
}

.export-data-date-picker {
  text-align: center;
  border-radius: 7px;
  border: 1px solid rgb(129, 129, 129);
}

.label-datepicker {
  margin-right: 10px;

  width: 40px;
}

.main-selector-container {
  display: flex;
  position: fixed;
  top: 14px;
  left: 8%;
  right: 75%;
  transform: none;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: white;
  gap: 10px;
  z-index: 1000;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  zoom: 1; /* Reset zoom */
  overflow: hidden; /* Hide overflowing text */
}

/* Button styling */

button:hover {
  background-color: #7f8ba1;
  color: white;
  transform: scale(1.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transform: none !important;
  zoom: 1; /* Reset zoom */
}

button {
  pointer-events: all; /* Ensure the button is interactive */
  z-index: auto; /* Reset z-index unless you have a specific need for 1000 */
  cursor: pointer;
  border: 1px solid #7f8ba1;
  background-color: white;
  color: #7f8ba1;
  font-size: 12px;
  padding: 1px 9px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
  font-family: Arial, sans-serif;
  transform: none !important;
  zoom: 1;
}

.dropdown,
select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 120px;
  box-sizing: border-box;
}
