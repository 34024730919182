
@import 'localFonts/customFont.css';

.operations_collector {
	display: flex;
    flex-direction: row;
    padding: 5vh;
    height: 100vh;
    overflow-y: scroll;
}

#buttonsContainer{
	display: flex;
	flex-flow: wrap;
	justify-content: space-evenly;
	align-items: center;
}

.mainButtonContainer{
	height: 180px;
	width: 250px;
    border: 1px solid rgba(0,0,0,.125);
	border-radius: 3px;
	background-color: rgba(255, 255, 255, 0.7);
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
}

.cardColor {
	width: 35px;
	height: 10px;
}

.cardButton {
	background-color: #6ff1d1;
	border-radius: 3px;
	border-style: none;
	padding: 5px;
	font-weight: 600;
}

#back2users{
	border: 1px solid rgba(0,0,0,.125);
	background-color: rgba(255, 255, 255, 0.7);
	box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
	height: fit-content;
	padding: 20px;
	width: 100px;
}
#back2users:hover{
	cursor: pointer;
	background: black;
	color: white;
} 

/*

Everything under here can be deleted!

*/


	/*under operations_collector*/
	/* #searchFor {
    padding: 10px;
        background: white;
    width: 20vw;
    height: 30px;
    position:fixed;
    left:5vw;
    border-radius:3px;
    border : 1px solid #000;
    top:0.5vh;
    transition: transform 1s;
    z-index: 9999999;
    text-align: center;
    color: black;
    padding-top: 0px;
	}

	#searchFor input {
	    height: 22px;
	}

	#searchFor > div {
	    visibility: hidden;

	}

	#searchFor:hover > div {
	    visibility: visible !important;
	}

	#searchFor:hover {
	    text-align: left;
	    padding: 10px;
	    padding-top: 0px;
	    width: 20vw;
	    height: 99vh;
	    z-index: 789123123789123789;
	    overflow-x: auto;
	    visibility: visible;
	    border-radius: 3px;
	    overflow-y: auto;
	    border : 1px solid SteelBlue;
	}


	#thisConfig {
    background: white;
    width: 3vw;
    height: 30px;
    position:fixed;
    left:1.5vw;
    border-radius: 3px;
    border : 1px solid #000;
    top:0.5vh;
    padding-top: 3.5px;
    transition: transform 1s;
    z-index: 9999999;
    text-align: center;
    color: black;

	} */

	/*under operations_collector*/
	/*unclear what this actually do*/
	/* #thisConfig > div {
	    visibility: hidden;
	    color: black;
	}

	#thisConfig > p {
	    margin-bottom: 8px;
	}

	#thisConfig:hover>div {
	    text-align: left;
	    background: white;
	    padding: 10px;
	    width: 23.46vw;
	    height: 95.7vh;
	    z-index: 789123123789123789;
	    overflow-x: auto;
	    visibility: visible;
	    border-radius: 3px;
	    overflow-y: auto;
	    border : 1px solid SteelBlue;
	}



	#thisConfig:hover > div {
	    visibility: visible;
	} */

	/*under operations_collector*/
	/* #back2users {
    padding: 10px;
        background: white;
    width: 10vw;
    padding-top: 3.5px;
    height: 30px;
    position:fixed;
    right:3px;
    border-radius: 3px;
    border : 1px solid #000;
    top:0.5vh;
    transition: transform 1s;
    z-index: 9999999;
    text-align: center;
    color: black;
	}

	#back2users:hover{
	    cursor: pointer;
	    background: black;
	    color: white;
	} */




	/*class="card m5" is under operations_collector but only seems to have css inline like style="width=20rem*/
	/*can't find any css code linked to any of the objects under class="card m5" object but they seem to have been styled.*/


