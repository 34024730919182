@import "localFonts/customFont.css";

/*background that is visible if a formsheet is visble to make the background more grey*/

.formSheet {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  -webkit-box-shadow: 0px 5px 73px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 73px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 20px -4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 20px;
  transition: transform 1s;
}

#formsheetBackBtn{
  border-style: none;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: transparent;
  font-size: 1.1em;
}

#formsheetBackBtn:hover{
  cursor: pointer;
}

#buttonRowCon{
  display: flex;
  align-items: center;
}

#backP{
  font-size: 1.2em;
  margin: 0;
  margin-left: 10px;
}

#documentaionsRow{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}

.formsheetH1{
  font-family: Calibri;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  white-space: normal;
  color: #000;
}

.SHOW_DOCUMENTATION {
  font-size: 1.2em;
  border-style: none;
  background-color: white;
}
.SHOW_DOCUMENTATION:hover {
  color: blue;
  font-weight: bold;
  cursor: pointer;
}

.shareholderRow {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  border-bottom: 1.5px solid #b6b6b6;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}
.shareholderColumn {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  border-bottom: 1.5px solid #b6b6b6;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
}
.shareHolderInput{
  font-size: 1.2em;
  padding: 5px;
  border-style: none;

}

.labelTitle {
  font-family: Calibri;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  white-space: normal;
  color: black;
}

#executeButton{
  border-radius: 3px;
  letter-spacing: 1.5px;
  font-size: 1.2em;
  padding-left: 20px;
  padding-right: 20px;
  color: black;
  background: white;
  border: 1px solid transparent;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
#executeButton:hover{
  cursor: pointer;
}


/*



































*/


.overlay {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  background-color: rgba(256, 256, 256, 0.5);
  z-index: 1010;
}

/*This is the json object that shows some object when selected, comes up besides the formsheet*/
#viewObject {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: 75vh;

  overflow-x: hidden;
  overflow-y: auto;
  background: white;

  border-radius: 6px;
  transition: transform 1s;
  padding: 20px;
}

/*the class of the #viewObject*/
.viewObjectAnimation2 {
  transform: translate(55%, -50%) !important;
  -webkit-box-shadow: 0px 5px 73px -8px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 73px -8px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 20px -4px rgba(0, 0, 0, 0.3);
}

/*if jsonToShow === null => create formsheet*/


/*another classname for formsheet.*/
.viewObjectAnimation {
  transform: translate(-75%, -50%);
}

/*under formsheet*/
.container_formsheet {
  width: 100%;
  padding: 0;
}

/*under container/col 12*/

/*under container/<form>.*/
/*This is a question in a form nad should be renamed.*/

/*under shareholder/<div>/<div>*/
.showAlterntives {
  margin-top: -5px;
  display: block;
  margin-bottom: 10px;
  width: 20% !important;
  text-align: center;
  background: white;
  border: 1px solid black;
  color: black;
  padding: 5px;
  letter-spacing: 1px;
}

.showAlterntives:hover {
  cursor: pointer;
  background: white !important;
  color: black !important;
}

/*under shareholder/<div>*/
.alternativesDropDown {
  width: 100%;
  border: 0.5px black solid;
  padding: 10px;
  margin-top: 6px;
  min-height: 100px;
}

/*under alternativesDropDown*/
.listOfAleternatives {
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-count: 3;
  -webkit-column-gap: 20px;
  column-count: 3;
  column-gap: 20px;
  min-height: 100px;
}

/*under listOfAlternatives*/
.listItem {
  cursor: pointer;
  color: black;
  padding-left: 5px;
}

.listItem:hover {
  color: black !important;
  background: Linen;
}

/*under container/<form>*/
/*this is a second name for some shareholder divs*/
/* .fileInput {
  margin-top: 10px;
  border-bottom: 1.5px solid #b6b6b6;
  padding-bottom: 10px;
} */

/*under fileinput*/
