@import 'localFonts/customFont.css';


.file-types-container-customer{
    height: 95%;
    width: 90%;
    margin-left: calc(120px);
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
}


.file-box-container-customer {
    width: 80%;
    height: calc(100% - 60px);
    /* background-color: green; */
    overflow-x: hidden;
    overflow-y: hidden;

    /* border-radius: 15px; */
    margin: 30px;
    margin-left: 30px;
    min-width: 450px;
}


.file-box-container-customer h2 {
    height: 10%;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    color: #575757;
    font-size: large;
    font-weight: bold;
}

.file-search-container {
    width: 100%;
    height: 10%;
}

#first_date {
    width: 30%;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 0px;
    display: inline-block;
}

#second_date {
    width: 30%;
    display: inline-block;
    margin-bottom: 0px;

}


@media screen and (max-width: 1120px) {
    .file-types-container-customer {
        display: block;
        margin-left: 110px;
    }

    .file-box-container-customer {
        width: 90%;
        min-width: 200px;
        height: 50%;
    }
  }



/* a lot has been deprecated below as we switched to tabulator */






.file-box-container-customer table {
    height: 90%;
    --rowHeight: 16px;
}

.file-box-container-customer thead {
    width: 100%;
    height: 25%;
}
.file-box-container-customer tbody {
    display: block;
    height: 70%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 15px;
}

.file-box-container-customer table tr {
    width: 100%;
}


.file-box-container-customer table thead tr:first-child{
    border-bottom-width: 2px;
    border-bottom-color: #979797;
}

.file-box-container-customer th {
    font-weight: normal;
}

.file-table table tr {
    height: var(--rowHeight);
}

/* .file-box-container-customer table td {
    padding-top: 1px;
    padding-bottom: 1px;
} */


.file-box-container-customer img {
    cursor: pointer;
    height: var(--rowHeight);
}


