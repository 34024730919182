#MainNavContainerDashBoard {
  background-color: #23283c;
  min-height: 100vh;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

#dashboardUL {
  display: flex;
  flex-direction: column;
  height: 100%;

  /* new */
  /* max-height: 100%; */
  overflow-y: auto;
}

.dashListItem {
  position: relative;
  margin-top: 10px;
}

.dashLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashBoardTitle {
  font-family: Montserrat, sans serif;
  color: #f1f1f1;
  margin-top: 10px;
  font-size: 0.8em;
}

.invisDiv {
  height: 45px;
  width: 45px;
  padding: 5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleDiv {
  height: 45px;
  width: 45px;
  padding: 5px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20bc9d;
}

.small-circleDiv {
  height: 25px;
  width: 25px;
}

.sidemenu-icon {
  max-width: 90%;
  max-height: 90%;
}

#myAccountItem {
  border-top: 1px solid rgb(217, 217, 217);
  /* position: fixed;
    bottom: 25px;
    left: 26px; */
}

#accImg {
  filter: invert(99%) sepia(2%) saturate(35%) hue-rotate(9deg) brightness(115%)
    contrast(89%);
  max-width: 90%;
}

#logoutMenuButton {
  cursor: pointer;
  margin-bottom: 10px;
}

/* Responsive adjustments for laptop screens */
@media (max-height: 1024px) {
  .invisDiv,
  .circleDiv {
    height: 30px;
    width: 30px;
    padding: 3px;
  }
}

@media (max-height: 700px) {
  .invisDiv,
  .circleDiv {
    height: 20px;
    width: 20px;
    padding: 2px;
  }

  .dashBoardTitle {
    margin-bottom: 0;
    font-size: 0.7em;
  }
}
