/* From tabulator_bootstrap4.css 2020-10-21 but changed so only applied to  .table-tabulator-customer.tabulator  elements */
@import 'localFonts/customFont.css';


.table-tabulator-customer.tabulator {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  font-size: 1rem;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator.no-borders {
  /* position: absolute; */
  /* bottom: 0; */
  /* top: 0; */
  border-bottom: 0px solid #b1b8c8;
  display: flex;
  /* height: 50%; */

}

.table-tabulator-customer.tabulator.no-borders .tabulator-tableHolder {
  border-bottom: 0px;
}

.table-tabulator-customer.tabulator.no-borders .tabulator-row {
  height: 30px;
  min-height: 20px;
}

.table-tabulator-customer.tabulator.no-borders .tabulator-row .tabulator-cell{
  padding-bottom: 5px;
  padding-top: 5px;
  /* font-size: 1rem; */
  height: 30px;
  min-height: 20px;
  /* height: 13px; */
  /* color:rgba(26, 26, 26, 0.938) */
}


.table-tabulator-customer.tabulator[tabulator-layout="fitDataFill"]
  .tabulator-tableHolder
  .tabulator-table {
  min-width: 100%;
}

.table-tabulator-customer.tabulator.tabulator-block-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-tabulator-customer.tabulator .tabulator-header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-top: 0px solid #b1b8c8;
  border-bottom: 2px solid #b1b8c8;
  background-color: #fff;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  font-size: 0.85em;
}

.table-tabulator-customer.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.table-tabulator-customer.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-moving {
  position: absolute;
  border: 1px solid #b1b8c8;
  background: #e6e6e6;
  pointer-events: none;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content {
  box-sizing: border-box;
  position: relative;
  padding: 0.75rem;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-header-menu-button {
  padding: 0 8px;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-header-menu-button:hover {
  cursor: pointer;
  opacity: 0.6;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
  text-align: center;
}

.tabulator-col-title {
  color: rgb(3, 3, 3);
  text-transform: uppercase;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-col-title
  .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #999;
  padding: 1px;
  background: #fff;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content
  .tabulator-arrow {
  display: none;
  /* display: inline-block; */
  position: absolute;
  top: 14px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #bbb;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-group
  .tabulator-col-group-cols {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #b1b8c8;
  overflow: hidden;
  margin-right: -1px;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col:first-child
  .tabulator-col-resize-handle.prev {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter {
  position: relative;
  box-sizing: border-box;
  margin-top: 2px;
  width: 100%;
  text-align: center;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter
  textarea {
  height: auto !important;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter
  svg {
  margin-top: 3px;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-header-filter
  input::-ms-clear {
  width: 0;
  height: 0;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
  background-color: #e6e6e6;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="asc"]
  .tabulator-col-content
  .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #666;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="desc"]
  .tabulator-col-content
  .tabulator-arrow {
  border-top: 6px solid #666;
  border-bottom: none;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical
  .tabulator-col-content
  .tabulator-col-title {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip
  .tabulator-col-title {
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-col-title {
  padding-right: 0;
  padding-top: 20px;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip
  .tabulator-col-title {
  padding-right: 0;
  padding-bottom: 20px;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-col.tabulator-col-vertical.tabulator-sortable
  .tabulator-arrow {
  right: calc(50% - 6px);
}

.table-tabulator-customer.tabulator .tabulator-header .tabulator-frozen {
  display: inline-block;
  position: absolute;
  z-index: 10;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  width: 100%;
  background: white !important;
  border-top: 1px solid #b1b8c8;
  border-bottom: 1px solid #b1b8c8;
  overflow: hidden;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-calcs-holder
  .tabulator-row {
  background: white !important;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-frozen-rows-holder {
  min-width: 600%;
}

.table-tabulator-customer.tabulator
  .tabulator-header
  .tabulator-frozen-rows-holder:empty {
  display: none;
}

.table-tabulator-customer.tabulator .tabulator-tableHolder {
  position: relative;
  width: 100%;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* border-bottom: 1px solid #a1a1a1; */
  /* min-height: 100%; */
  /* max-height: 50%; */
  /* height: 100%; */
}

.table-tabulator-customer.tabulator .tabulator-tableHolder:focus {
  outline: none;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-placeholder {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-placeholder[tabulator-render-mode="virtual"] {
  min-height: 100%;
  min-width: 100%;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-placeholder
  span {
  display: inline-block;
  margin: 0 auto;
  padding: 10px;
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

.table-tabulator-customer.tabulator .tabulator-tableHolder .tabulator-table {
  position: relative;
  display: inline-block;
  background-color: #fff;
  white-space: nowrap;
  overflow: visible;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs {
  font-weight: bold;
  background: #ececec !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator .tabulator-col-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
}

.table-tabulator-customer.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.table-tabulator-customer.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.table-tabulator-customer.tabulator .tabulator-footer {
  padding: 5px 10px;
  border-top: 2px solid #b1b8c8;
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.table-tabulator-customer.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: calc(100% + 20px);
  margin: -5px -10px 5px -10px;
  text-align: left;
  background: white !important;
  border-bottom: 1px solid #b1b8c8;
  border-top: 1px solid #b1b8c8;
  overflow: hidden;
}

.table-tabulator-customer.tabulator
  .tabulator-footer
  .tabulator-calcs-holder
  .tabulator-row {
  background: white !important;
}

.table-tabulator-customer.tabulator
  .tabulator-footer
  .tabulator-calcs-holder
  .tabulator-row
  .tabulator-col-resize-handle {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-footer
  .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
  border-bottom: none;
}

.table-tabulator-customer.tabulator .tabulator-footer .tabulator-paginator {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.table-tabulator-customer.tabulator .tabulator-footer .tabulator-page-size {
  display: inline-block;
  margin: 0 5px;
  padding: 2px 5px;
  border: 1px solid #b1b8c8;
  border-radius: 3px;
}

.table-tabulator-customer.tabulator .tabulator-footer .tabulator-page {
  display: inline-block;
  margin: 0;
  margin-top: 5px;
  padding: 8px 12px;
  border: 1px solid #b1b8c8;
  border-right: none;
  background: rgba(255, 255, 255, 0.2);
}

.table-tabulator-customer.tabulator
  .tabulator-footer
  .tabulator-page[data-page="first"] {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.table-tabulator-customer.tabulator
  .tabulator-footer
  .tabulator-page[data-page="last"] {
  border: 1px solid #b1b8c8;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.table-tabulator-customer.tabulator .tabulator-footer .tabulator-page.active {
  border-color: #007bff;
  background-color: #007bff;
  color: #fff;
}

.table-tabulator-customer.tabulator .tabulator-footer .tabulator-page:disabled {
  border-color: #b1b8c8;
  background: #fff;
  color: #6c757d;
}

.table-tabulator-customer.tabulator
  .tabulator-footer
  .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  border-color: #b1b8c8;
  background: #e9ecef;
  color: #0056b3;
}

.table-tabulator-customer.tabulator .tabulator-loader {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
}

.table-tabulator-customer.tabulator .tabulator-loader .tabulator-loader-msg {
  display: inline-block;
  margin: 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
  background: #fff;
  font-weight: bold;
  font-size: 16px;
}

.table-tabulator-customer.tabulator
  .tabulator-loader
  .tabulator-loader-msg.tabulator-loading {
  border: 4px solid #333;
  color: #000;
}

.table-tabulator-customer.tabulator
  .tabulator-loader
  .tabulator-loader-msg.tabulator-error {
  border: 4px solid #d00;
  color: #590000;
}

.table-tabulator-customer.tabulator.thead-dark .tabulator-header {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.table-tabulator-customer.tabulator.thead-dark
  .tabulator-header
  .tabulator-col {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.table-tabulator-customer.tabulator.table-dark {
  background-color: #212529;
}

.table-tabulator-customer.tabulator.table-dark:not(.thead-light)
  .tabulator-header {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.table-tabulator-customer.tabulator.table-dark:not(.thead-light)
  .tabulator-header
  .tabulator-col {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.table-tabulator-customer.tabulator.table-dark .tabulator-tableHolder {
  color: #fff;
}

.table-tabulator-customer.tabulator.table-dark .tabulator-row {
  border-color: #32383e;
  background-color: #212529;
  color: #fff;
}

.table-tabulator-customer.tabulator.table-dark .tabulator-row:hover {
  background-color: #32383e;
}

.table-tabulator-customer.tabulator.table-dark
  .tabulator-row:hover
  .tabulator-cell {
  background-color: rgba(255, 255, 255, 0.075);
}

.tabulator-cell {
  font-weight: bold;
  font-family: inherit;
  color: gray;
  display: flex;
  justify-content: center;
  overflow: hidden;
  font-size: 12px;
}

.tabulator-cell:hover {
  overflow: unset;
  color: #000;
  z-index: 2;
  background: lightgrey;
  transition: all 0.4s ease-in-out;
}

.table-tabulator-customer.tabulator.table-dark
  .tabulator-row.tabulator-selected {
  background-color: #9abcea;
}

.table-tabulator-customer.tabulator.table-striped:not(.table-dark)
  .tabulator-row:nth-child(even) {
  background-color: #f9f9f9;
}

.table-tabulator-customer.tabulator.table-striped:not(.table-dark)
  .tabulator-row:nth-child(even).tabulator-selected {
  background-color: #9abcea;
}

.table-tabulator-customer.tabulator.table-striped:not(.table-dark)
  .tabulator-row:nth-child(even).tabulator-selectable:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.table-tabulator-customer.tabulator.table-striped:not(.table-dark)
  .tabulator-row:nth-child(even).tabulator-selected:hover {
  background-color: #769bcc;
  cursor: pointer;
}

.table-tabulator-customer.tabulator.table-striped.table-dark
  .tabulator-row:nth-child(even)
  .tabulator-cell {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-tabulator-customer.tabulator.table-bordered {
  border: 1px solid #b1b8c8;
}

.table-tabulator-customer.tabulator.table-bordered
  .tabulator-header
  .tabulator-col {
  border-right: 1px solid #b1b8c8;
}

.table-tabulator-customer.tabulator.table-bordered
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell {
  border-right: 1px solid #b1b8c8;
}

.table-tabulator-customer.tabulator.table-borderless .tabulator-header {
  border: none;
}

.table-tabulator-customer.tabulator.table-borderless .tabulator-row {
  border: none;
}

.table-tabulator-customer.tabulator.table-sm
  .tabulator-header
  .tabulator-col
  .tabulator-col-content {
  padding: 0.3rem !important;
}

.table-tabulator-customer.tabulator.table-sm
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row {
  min-height: 1.6rem;
}

.table-tabulator-customer.tabulator.table-sm
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell {
  padding: 0.3rem !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-primary {
  background: #b8daff !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-secondary {
  background: #d6d8db !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-success {
  background: #c3e6cb !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-info {
  background: #bee5eb !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-warning {
  background: #ffeeba !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-danger {
  background: #f5c6cb !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-light {
  background: #fdfdfe !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-dark {
  background: #c6c8ca !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.table-active {
  background: #f5f5f5 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-primary {
  background: #007bff !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-secondary {
  background: #6c757d !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-success {
  background: #28a745 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-info {
  background: #17a2b8 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-warning {
  background: #ffc107 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-danger {
  background: #dc3545 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-light {
  background: #f8f9fa !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-dark {
  background: #343a40 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row.bg-active {
  background: #f5f5f5 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-primary {
  background: #b8daff !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-secondary {
  background: #d6d8db !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-success {
  background: #c3e6cb !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-info {
  background: #bee5eb !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-warning {
  background: #ffeeba !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-danger {
  background: #f5c6cb !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-light {
  background: #fdfdfe !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-dark {
  background: #c6c8ca !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.table-active {
  background: #f5f5f5 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-primary {
  background: #007bff !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-secondary {
  background: #6c757d !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-success {
  background: #28a745 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-info {
  background: #17a2b8 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-warning {
  background: #ffc107 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-danger {
  background: #dc3545 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-light {
  background: #f8f9fa !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-dark {
  background: #343a40 !important;
}

.table-tabulator-customer.tabulator
  .tabulator-tableHolder
  .tabulator-table
  .tabulator-row
  .tabulator-cell.bg-active {
  background: #f5f5f5 !important;
}

.table-tabulator-customer.tabulator .tabulator-row {
  position: relative;
  box-sizing: border-box;
  min-height: 2.5rem;
  background-color: #fff;
  border-bottom: 1px solid #b1b8c8;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-selectable:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-selected {
  background-color: #9abcea;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-selected:hover {
  background-color: #769bcc;
  cursor: pointer;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-moving {
  position: absolute;
  border-top: 1px solid #b1b8c8;
  border-bottom: 1px solid #b1b8c8;
  pointer-events: none !important;
  z-index: 15;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-row-resize-handle {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.table-tabulator-customer.tabulator .tabulator-row .tabulator-frozen {
  display: inline-block;
  position: absolute;
  background-color: inherit;
  z-index: 10;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-responsive-collapse {
  box-sizing: border-box;
  padding: 5px;
  border-top: 1px solid #b1b8c8;
  border-bottom: 1px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-responsive-collapse:empty {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-responsive-collapse
  table {
  font-size: 1rem;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-responsive-collapse
  table
  tr
  td {
  position: relative;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-responsive-collapse
  table
  tr
  td:first-of-type {
  padding-right: 10px;
}

.table-tabulator-customer.tabulator .tabulator-row .tabulator-cell {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 0.75rem;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tabulator-cell {
  padding-left: 10px;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell:last-of-type {
  border-right: none;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-editing {
  border: 1px solid #1d68cd;
  padding: 0;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-editing
  input,
.tabulator-row .tabulator-cell.tabulator-editing select {
  border: 1px;
  background: transparent;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #dd0000;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-validation-fail
  input,
.tabulator-row .tabulator-cell.tabulator-validation-fail select {
  border: 1px;
  background: transparent;
  color: #dd0000;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell:first-child
  .tabulator-col-resize-handle.prev {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-row-handle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-row-handle
  .tabulator-row-handle-box {
  width: 80%;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell.tabulator-row-handle
  .tabulator-row-handle-box
  .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  margin-top: 2px;
  background: #666;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid #b1b8c8;
  border-bottom: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  height: 17px;
  width: 17px;
  margin-right: 5px;
  border: 1px solid rgb(146, 146, 146);
  border-radius: 1000px;
  background: rgb(255, 255, 255);
  overflow: hidden;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: rgb(146, 146, 146);
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: rgb(146, 146, 146);
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: rgb(146, 146, 146);
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  background: #666;
  color: #fff;
  font-weight: bold;
  font-size: 1.1em;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle:hover {
  opacity: 0.7;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle.open
  .tabulator-responsive-collapse-toggle-open {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-responsive-collapse-toggle
  .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-traffic-light {
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 14px;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-group {
  box-sizing: border-box;
  border-bottom: 1px solid #999;
  border-right: 1px solid #b1b8c8;
  border-top: 1px solid #999;
  padding: 5px;
  padding-left: 10px;
  background: #fafafa;
  font-weight: bold;
  min-width: 100%;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group.tabulator-group-visible
  .tabulator-arrow {
  margin-right: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  border-bottom: 0;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group
  .tabulator-group-toggle {
  display: inline-block;
}

.table-tabulator-customer.tabulator
  .tabulator-row.tabulator-group
  .tabulator-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 16px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 0;
  border-left: 6px solid #666;
  vertical-align: middle;
}

.table-tabulator-customer.tabulator .tabulator-row.tabulator-group span {
  margin-left: 10px;
  color: #666;
}

.table-tabulator-customer.tabulator .tabulator-menu {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #b1b8c8;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.table-tabulator-customer.tabulator .tabulator-menu .tabulator-menu-item {
  padding: 5px 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-tabulator-customer.tabulator
  .tabulator-menu
  .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: 0.5;
}

.table-tabulator-customer.tabulator
  .tabulator-menu
  .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  cursor: pointer;
  background: #f9f9f9;
}

.table-tabulator-customer.tabulator .tabulator-menu .tabulator-menu-separator {
  border-top: 1px solid #b1b8c8;
}

.table-tabulator-customer.tabulator .tabulator-edit-select-list {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  max-height: 200px;
  background: #fff;
  border: 1px solid #b1b8c8;
  font-size: 1rem;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
}

.table-tabulator-customer.tabulator
  .tabulator-edit-select-list
  .tabulator-edit-select-list-item {
  padding: 4px;
}

.table-tabulator-customer.tabulator
  .tabulator-edit-select-list
  .tabulator-edit-select-list-item.active {
  color: #fff;
  background: #1d68cd;
}

.table-tabulator-customer.tabulator
  .tabulator-edit-select-list
  .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  color: #fff;
  background: #1d68cd;
}

.table-tabulator-customer.tabulator
  .tabulator-edit-select-list
  .tabulator-edit-select-list-notice {
  padding: 4px;
  text-align: center;
}

.table-tabulator-customer.tabulator
  .tabulator-edit-select-list
  .tabulator-edit-select-list-group {
  border-bottom: 1px solid #b1b8c8;
  padding: 4px;
  padding-top: 6px;
  font-weight: bold;
}

.table-tabulator-customer.tabulator .tabulator-print-fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.table-tabulator-customer.tabulator
  body.tabulator-print-fullscreen-hide
  > *:not(.tabulator-print-fullscreen) {
  display: none !important;
}

.table-tabulator-customer.tabulator .tabulator-print-table {
  border-collapse: collapse;
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-branch {
  display: inline-block;
  vertical-align: middle;
  height: 9px;
  width: 7px;
  margin-top: -9px;
  margin-right: 5px;
  border-bottom-left-radius: 1px;
  border-left: 2px solid #b1b8c8;
  border-bottom: 2px solid #b1b8c8;
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-control {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  height: 11px;
  width: 11px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: transparent;
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-collapse:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #ccc;
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand {
  display: inline-block;
  position: relative;
  height: 7px;
  width: 1px;
  background: #ccc;
}

.table-tabulator-customer.tabulator
  .tabulator-print-table
  .tabulator-data-tree-control
  .tabulator-data-tree-control-expand:after {
  position: absolute;
  content: "";
  left: -3px;
  top: 3px;
  height: 1px;
  width: 7px;
  background: #ccc;
}
