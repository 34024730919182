/* From tabulator_bootstrap4.css 2020-10-21 but chnged so only applied to  .file-table-tabulator.tabulator  elements */
@import 'localFonts/customFont.css';


.file-table-tabulator.tabulator {
    position: relative;
    /* background-color: #fff; */
    overflow: hidden;
    font-size: 1rem;
    text-align: left;
    width: 100%;
    max-width: 100%;
    -ms-transform: translatez(0);
    transform: translatez(0);
    height: 70%;
    max-height: 700px;
    margin: 15px 0px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
  
  .file-table-tabulator.tabulator[tabulator-layout="fitDataFill"]
    .tabulator-tableHolder
    .tabulator-table {
    min-width: 100%;
  }
  
  .file-table-tabulator.tabulator.tabulator-block-select {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .file-table-tabulator.tabulator .tabulator-header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-top: 0px solid #b1b8c8;
    border-bottom: 2px solid #b1b8c8;
    /* background-color: #fff; */
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    font-size: 0.85em;
  }
  
  .file-table-tabulator.tabulator .tabulator-header.tabulator-header-hidden {
    display: none;
  }
  
  .file-table-tabulator.tabulator .tabulator-header .tabulator-col {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    /* background-color: #fff; */
    text-align: left;
    vertical-align: bottom;
    overflow: hidden;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-moving {
    position: absolute;
    border: 1px solid #b1b8c8;
    background: #e6e6e6;
    pointer-events: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-col-content {
    box-sizing: border-box;
    position: relative;
    /* padding: 0.75rem; */
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-col-content
    .tabulator-header-menu-button {
    padding: 0 8px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-col-content
    .tabulator-header-menu-button:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-col-content
    .tabulator-col-title {
    box-sizing: border-box;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }
  
  .tabulator-col-title {
    color: rgb(3, 3, 3);
    text-transform: uppercase;
  }

  .file-table-tabulator .tabulator-col-title {
    color: rgb(70, 70, 70);
    text-transform: uppercase;
    text-align: center;
  }

  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-col-content
    .tabulator-col-title
    .tabulator-title-editor {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #999;
    padding: 1px;
    background: #fff;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-col-content
    .tabulator-arrow {
    display: none;
    /* display: inline-block; */
    position: absolute;
    top: 14px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #bbb;
  }

  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-col-group
    .tabulator-col-group-cols {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid #b1b8c8;
    overflow: hidden;
    margin-right: -1px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col:first-child
    .tabulator-col-resize-handle.prev {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-header-filter {
    position: relative;
    box-sizing: border-box;
    margin-top: 2px;
    width: 100%;
    text-align: center;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-header-filter
    textarea {
    height: auto !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-header-filter
    svg {
    margin-top: 3px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col
    .tabulator-header-filter
    input::-ms-clear {
    width: 0;
    height: 0;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable
    .tabulator-col-title {
    padding-right: 25px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable:hover {
    cursor: pointer;
    background-color: #e6e6e6;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="none"]
    .tabulator-col-content
    .tabulator-arrow {
    border-top: none;
    border-bottom: 6px solid #bbb;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="asc"]
    .tabulator-col-content
    .tabulator-arrow {
    border-top: none;
    border-bottom: 6px solid #666;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-sortable[aria-sort="desc"]
    .tabulator-col-content
    .tabulator-arrow {
    border-top: 6px solid #666;
    border-bottom: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-col-vertical
    .tabulator-col-content
    .tabulator-col-title {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip
    .tabulator-col-title {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-col-vertical.tabulator-sortable
    .tabulator-col-title {
    padding-right: 0;
    padding-top: 20px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip
    .tabulator-col-title {
    padding-right: 0;
    padding-bottom: 20px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-col.tabulator-col-vertical.tabulator-sortable
    .tabulator-arrow {
    right: calc(50% - 6px);
  }
  
  .file-table-tabulator.tabulator .tabulator-header .tabulator-frozen {
    display: inline-block;
    position: absolute;
    z-index: 10;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-frozen.tabulator-frozen-left {
    border-right: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-frozen.tabulator-frozen-right {
    border-left: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator .tabulator-header .tabulator-calcs-holder {
    box-sizing: border-box;
    width: 100%;
    background: white !important;
    border-top: 1px solid #b1b8c8;
    border-bottom: 1px solid #b1b8c8;
    overflow: hidden;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-calcs-holder
    .tabulator-row {
    background: white !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-calcs-holder
    .tabulator-row
    .tabulator-col-resize-handle {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-frozen-rows-holder {
    min-width: 600%;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-header
    .tabulator-frozen-rows-holder:empty {
    display: none;
  }
  
  .file-table-tabulator.tabulator .tabulator-tableHolder {
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    /* border-bottom: 1px solid #a1a1a1; */
    /* min-height: 100%; */
    /* max-height: 100%; */
    /* height: 100%; */
  }
  
  .file-table-tabulator.tabulator .tabulator-tableHolder:focus {
    outline: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-placeholder {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-placeholder[tabulator-render-mode="virtual"] {
    min-height: 100%;
    min-width: 100%;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-placeholder
    span {
    display: inline-block;
    margin: 0 auto;
    padding: 10px;
    color: rgb(128, 128, 128);
    font-weight: bold;
    font-size: 20px;
  }
  
  .file-table-tabulator.tabulator .tabulator-tableHolder .tabulator-table {
    position: relative;
    display: inline-block;
    background-color: #fff;
    white-space: nowrap;
    overflow: visible;
    /* width: 100%; */
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.tabulator-calcs {
    font-weight: bold;
    background: #ececec !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.tabulator-calcs.tabulator-calcs-top {
    border-bottom: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
    border-top: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator .tabulator-col-resize-handle {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
  }
  
  .file-table-tabulator.tabulator .tabulator-col-resize-handle.prev {
    left: 0;
    right: auto;
  }
  
  .file-table-tabulator.tabulator .tabulator-col-resize-handle:hover {
    cursor: ew-resize;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer {
    padding: 5px 10px;
    border-top: 2px solid #b1b8c8;
    text-align: right;
    font-weight: bold;
    white-space: nowrap;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer .tabulator-calcs-holder {
    box-sizing: border-box;
    width: calc(100% + 20px);
    margin: -5px -10px 5px -10px;
    text-align: left;
    background: white !important;
    border-bottom: 1px solid #b1b8c8;
    border-top: 1px solid #b1b8c8;
    overflow: hidden;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-footer
    .tabulator-calcs-holder
    .tabulator-row {
    background: white !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-footer
    .tabulator-calcs-holder
    .tabulator-row
    .tabulator-col-resize-handle {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-footer
    .tabulator-calcs-holder:only-child {
    margin-bottom: -5px;
    border-bottom: none;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer .tabulator-paginator {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer .tabulator-page-size {
    display: inline-block;
    margin: 0 5px;
    padding: 2px 5px;
    border: 1px solid #b1b8c8;
    border-radius: 3px;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer .tabulator-page {
    display: inline-block;
    margin: 0;
    margin-top: 5px;
    padding: 8px 12px;
    border: 1px solid #b1b8c8;
    border-right: none;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-footer
    .tabulator-page[data-page="first"] {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-footer
    .tabulator-page[data-page="last"] {
    border: 1px solid #b1b8c8;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer .tabulator-page.active {
    border-color: #007bff;
    background-color: #007bff;
    color: #fff;
  }
  
  .file-table-tabulator.tabulator .tabulator-footer .tabulator-page:disabled {
    border-color: #b1b8c8;
    background: #fff;
    color: #6c757d;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-footer
    .tabulator-page:not(.disabled):hover {
    cursor: pointer;
    border-color: #b1b8c8;
    background: #e9ecef;
    color: #0056b3;
  }
  
  .file-table-tabulator.tabulator .tabulator-loader {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
  }
  
  .file-table-tabulator.tabulator .tabulator-loader .tabulator-loader-msg {
    display: inline-block;
    margin: 0 auto;
    padding: 10px 20px;
    border-radius: 10px;
    background: #fff;
    font-weight: bold;
    font-size: 16px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-loader
    .tabulator-loader-msg.tabulator-loading {
    border: 4px solid #333;
    color: #000;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-loader
    .tabulator-loader-msg.tabulator-error {
    border: 4px solid #d00;
    color: #590000;
  }
  
  .file-table-tabulator.tabulator.thead-dark .tabulator-header {
    border-color: #32383e;
    background-color: #212529;
    color: #fff;
  }
  
  .file-table-tabulator.tabulator.thead-dark
    .tabulator-header
    .tabulator-col {
    border-color: #32383e;
    background-color: #212529;
    color: #fff;
  }
  
  .file-table-tabulator.tabulator.table-dark {
    background-color: #212529;
  }
  
  .file-table-tabulator.tabulator.table-dark:not(.thead-light)
    .tabulator-header {
    border-color: #32383e;
    background-color: #212529;
    color: #fff;
  }
  
  .file-table-tabulator.tabulator.table-dark:not(.thead-light)
    .tabulator-header
    .tabulator-col {
    border-color: #32383e;
    background-color: #212529;
    color: #fff;
  }
  
  .file-table-tabulator.tabulator.table-dark .tabulator-tableHolder {
    color: #fff;
  }
  
  .file-table-tabulator.tabulator.table-dark .tabulator-row {
    border-color: #32383e;
    background-color: #212529;
    color: #fff;
  }
  
  .file-table-tabulator.tabulator.table-dark .tabulator-row:hover {
    background-color: #32383e;
  }
  
  .file-table-tabulator.tabulator.table-dark
    .tabulator-row:hover
    .tabulator-cell {
    background-color: rgba(255, 255, 255, 0.075);

  }
  
  /* .tabulator-cell {
    font-weight: bold;
    color: rgb(3, 3, 3);
  } */
  
  .file-table-tabulator.tabulator.table-dark
    .tabulator-row.tabulator-selected {
    background-color: #9abcea;
  }
  
  .file-table-tabulator.tabulator.table-striped:not(.table-dark)
    .tabulator-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .file-table-tabulator.tabulator.table-striped:not(.table-dark)
    .tabulator-row:nth-child(even).tabulator-selected {
    background-color: #9abcea;
  }
  
  .file-table-tabulator.tabulator.table-striped:not(.table-dark)
    .tabulator-row:nth-child(even).tabulator-selectable:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .file-table-tabulator.tabulator.table-striped:not(.table-dark)
    .tabulator-row:nth-child(even).tabulator-selected:hover {
    background-color: #769bcc;
    cursor: pointer;
  }
  
  .file-table-tabulator.tabulator.table-striped.table-dark
    .tabulator-row:nth-child(even)
    .tabulator-cell {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .file-table-tabulator.tabulator.table-bordered {
    border: 1px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator.table-bordered
    .tabulator-header
    .tabulator-col {
    border-right: 1px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator.table-bordered
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell {
    border-right: 1px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator.table-borderless .tabulator-header {
    border: none;
  }
  
  .file-table-tabulator.tabulator.table-borderless .tabulator-row {
    border: none;
  }
  
  .file-table-tabulator.tabulator.table-sm
    .tabulator-header
    .tabulator-col
    .tabulator-col-content {
    padding: 0.3rem !important;
  }
  
  .file-table-tabulator.tabulator.table-sm
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row {
    min-height: 1.6rem;
  }
  
  .file-table-tabulator.tabulator.table-sm
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell {
    padding: 0.3rem !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-primary {
    background: #b8daff !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-secondary {
    background: #d6d8db !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-success {
    background: #c3e6cb !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-info {
    background: #bee5eb !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-warning {
    background: #ffeeba !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-danger {
    background: #f5c6cb !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-light {
    background: #fdfdfe !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-dark {
    background: #c6c8ca !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.table-active {
    background: #f5f5f5 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-primary {
    background: #007bff !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-secondary {
    background: #6c757d !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-success {
    background: #28a745 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-info {
    background: #17a2b8 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-warning {
    background: #ffc107 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-danger {
    background: #dc3545 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-light {
    background: #f8f9fa !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-dark {
    background: #343a40 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row.bg-active {
    background: #f5f5f5 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-primary {
    background: #b8daff !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-secondary {
    background: #d6d8db !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-success {
    background: #c3e6cb !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-info {
    background: #bee5eb !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-warning {
    background: #ffeeba !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-danger {
    background: #f5c6cb !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-light {
    background: #fdfdfe !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-dark {
    background: #c6c8ca !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.table-active {
    background: #f5f5f5 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-primary {
    background: #007bff !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-secondary {
    background: #6c757d !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-success {
    background: #28a745 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-info {
    background: #17a2b8 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-warning {
    background: #ffc107 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-danger {
    background: #dc3545 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-light {
    background: #f8f9fa !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-dark {
    background: #343a40 !important;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-tableHolder
    .tabulator-table
    .tabulator-row
    .tabulator-cell.bg-active {
    background: #f5f5f5 !important;
  }
  
  .file-table-tabulator.tabulator .tabulator-row {
    position: relative;
    box-sizing: border-box;
    min-height: 2.5rem;
    /* background-color: #fff; */
    background-color: rgb(248, 248, 248);
    border-bottom: 1px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-selectable:hover {
    background-color: rgb(231, 231, 231);
    cursor: pointer;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-selected {
    background-color: #9abcea;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-selected:hover {
    background-color: #769bcc;
    cursor: pointer;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-moving {
    position: absolute;
    border-top: 1px solid #b1b8c8;
    border-bottom: 1px solid #b1b8c8;
    pointer-events: none !important;
    z-index: 15;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-row-resize-handle {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 5px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-row-resize-handle.prev {
    top: 0;
    bottom: auto;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-row-resize-handle:hover {
    cursor: ns-resize;
  }
  
  .file-table-tabulator.tabulator .tabulator-row .tabulator-frozen {
    display: inline-block;
    position: absolute;
    background-color: inherit;
    z-index: 10;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-frozen.tabulator-frozen-left {
    border-right: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-frozen.tabulator-frozen-right {
    border-left: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-responsive-collapse {
    box-sizing: border-box;
    padding: 5px;
    border-top: 1px solid #b1b8c8;
    border-bottom: 1px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-responsive-collapse:empty {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-responsive-collapse
    table {
    font-size: 1rem;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-responsive-collapse
    table
    tr
    td {
    position: relative;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-responsive-collapse
    table
    tr
    td:first-of-type {
    padding-right: 10px;
  }
  
  .file-table-tabulator.tabulator .tabulator-row .tabulator-cell {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 0.75rem;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:rgb(87, 87, 87);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell:last-of-type {
    border-right: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-editing {
    border: 1px solid #1d68cd;
    padding: 0;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-editing
    input,
  .tabulator-row .tabulator-cell.tabulator-editing select {
    border: 1px;
    background: transparent;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-validation-fail {
    border: 1px solid #dd0000;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-validation-fail
    input,
  .tabulator-row .tabulator-cell.tabulator-validation-fail select {
    border: 1px;
    background: transparent;
    color: #dd0000;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell:first-child
    .tabulator-col-resize-handle.prev {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-row-handle {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-row-handle
    .tabulator-row-handle-box {
    width: 80%;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell.tabulator-row-handle
    .tabulator-row-handle-box
    .tabulator-row-handle-bar {
    width: 100%;
    height: 3px;
    margin-top: 2px;
    background: #666;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-branch {
    display: inline-block;
    vertical-align: middle;
    height: 9px;
    width: 7px;
    margin-top: -9px;
    margin-right: 5px;
    border-bottom-left-radius: 1px;
    border-left: 2px solid #b1b8c8;
    border-bottom: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-control {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    height: 17px;
    width: 17px;
    margin-right: 5px;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 1000px;
    background: rgb(255, 255, 255);
    overflow: hidden;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-control:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-control
    .tabulator-data-tree-control-collapse {
    display: inline-block;
    position: relative;
    height: 7px;
    width: 1px;
    background: transparent;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-control
    .tabulator-data-tree-control-collapse:after {
    position: absolute;
    content: "";
    left: -3px;
    top: 3px;
    height: 1px;
    width: 7px;
    background: rgb(146, 146, 146);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-control
    .tabulator-data-tree-control-expand {
    display: inline-block;
    position: relative;
    height: 7px;
    width: 1px;
    background: rgb(146, 146, 146);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-data-tree-control
    .tabulator-data-tree-control-expand:after {
    position: absolute;
    content: "";
    left: -3px;
    top: 3px;
    height: 1px;
    width: 7px;
    background: rgb(146, 146, 146);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-responsive-collapse-toggle {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    height: 15px;
    width: 15px;
    border-radius: 20px;
    background: #666;
    color: #fff;
    font-weight: bold;
    font-size: 1.1em;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-responsive-collapse-toggle:hover {
    opacity: 0.7;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-responsive-collapse-toggle.open
    .tabulator-responsive-collapse-toggle-close {
    display: initial;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-responsive-collapse-toggle.open
    .tabulator-responsive-collapse-toggle-open {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-responsive-collapse-toggle
    .tabulator-responsive-collapse-toggle-close {
    display: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row
    .tabulator-cell
    .tabulator-traffic-light {
    display: inline-block;
    height: 14px;
    width: 14px;
    border-radius: 14px;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-group {
    box-sizing: border-box;
    border-bottom: 1px solid #999;
    border-right: 1px solid #b1b8c8;
    border-top: 1px solid #999;
    padding: 5px;
    padding-left: 10px;
    background: #fafafa;
    font-weight: bold;
    min-width: 100%;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-group:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group.tabulator-group-visible
    .tabulator-arrow {
    margin-right: 10px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #666;
    border-bottom: 0;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group.tabulator-group-level-1 {
    padding-left: 30px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group.tabulator-group-level-2 {
    padding-left: 50px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group.tabulator-group-level-3 {
    padding-left: 70px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group.tabulator-group-level-4 {
    padding-left: 90px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group.tabulator-group-level-5 {
    padding-left: 110px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group
    .tabulator-group-toggle {
    display: inline-block;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-row.tabulator-group
    .tabulator-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 16px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 0;
    border-left: 6px solid #666;
    vertical-align: middle;
  }
  
  .file-table-tabulator.tabulator .tabulator-row.tabulator-group span {
    margin-left: 10px;
    color: #666;
  }
  
  .file-table-tabulator.tabulator .tabulator-menu {
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid #b1b8c8;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
  }
  
  .file-table-tabulator.tabulator .tabulator-menu .tabulator-menu-item {
    padding: 5px 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-menu
    .tabulator-menu-item.tabulator-menu-item-disabled {
    opacity: 0.5;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-menu
    .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
    cursor: pointer;
    background: #f9f9f9;
  }
  
  .file-table-tabulator.tabulator .tabulator-menu .tabulator-menu-separator {
    border-top: 1px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator .tabulator-edit-select-list {
    position: absolute;
    display: inline-block;
    box-sizing: border-box;
    max-height: 200px;
    background: #fff;
    border: 1px solid #b1b8c8;
    font-size: 1rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-edit-select-list
    .tabulator-edit-select-list-item {
    padding: 4px;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-edit-select-list
    .tabulator-edit-select-list-item.active {
    color: #fff;
    background: #1d68cd;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-edit-select-list
    .tabulator-edit-select-list-item:hover {
    cursor: pointer;
    color: #fff;
    background: #1d68cd;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-edit-select-list
    .tabulator-edit-select-list-notice {
    padding: 4px;
    text-align: center;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-edit-select-list
    .tabulator-edit-select-list-group {
    border-bottom: 1px solid #b1b8c8;
    padding: 4px;
    padding-top: 6px;
    font-weight: bold;
  }
  
  .file-table-tabulator.tabulator .tabulator-print-fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }
  
  .file-table-tabulator.tabulator
    body.tabulator-print-fullscreen-hide
    > *:not(.tabulator-print-fullscreen) {
    display: none !important;
  }
  
  .file-table-tabulator.tabulator .tabulator-print-table {
    border-collapse: collapse;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-branch {
    display: inline-block;
    vertical-align: middle;
    height: 9px;
    width: 7px;
    margin-top: -9px;
    margin-right: 5px;
    border-bottom-left-radius: 1px;
    border-left: 2px solid #b1b8c8;
    border-bottom: 2px solid #b1b8c8;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-control {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    height: 11px;
    width: 11px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-control:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-control
    .tabulator-data-tree-control-collapse {
    display: inline-block;
    position: relative;
    height: 7px;
    width: 1px;
    background: transparent;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-control
    .tabulator-data-tree-control-collapse:after {
    position: absolute;
    content: "";
    left: -3px;
    top: 3px;
    height: 1px;
    width: 7px;
    background: #ccc;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-control
    .tabulator-data-tree-control-expand {
    display: inline-block;
    position: relative;
    height: 7px;
    width: 1px;
    background: #ccc;
  }
  
  .file-table-tabulator.tabulator
    .tabulator-print-table
    .tabulator-data-tree-control
    .tabulator-data-tree-control-expand:after {
    position: absolute;
    content: "";
    left: -3px;
    top: 3px;
    height: 1px;
    width: 7px;
    background: #ccc;
  }
  