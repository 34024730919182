
@import 'localFonts/customFont.css';



.myAccount-main-container {
    font-size: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.account-information {
    width: 40vw;
    /* height: 40vh; */
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: left;
    padding: 20px;
}

.account-information-body{
    margin-top: 30px;
    margin-bottom: 30px;
}

.account-information-footer{
    margin-top: 30px;
    padding-bottom: 20px;
}

.account-information-field {
    display: block;
    margin-bottom: 20px;
}

.account-information-field * {
    display: inline-block;
}

.account-information-field h1 {
    text-align: right;
    font-size: 25px;
    margin-right: 10px;
    font-weight: bold;
}

.account-information-field h2 {
    text-align: left;
    font-size: 17px;
    margin-right: 10px;
}

.img-edit {
    width: 17px;
    margin-bottom: 6px;
    margin-right: 5px;
    cursor: pointer;
}

.account-information-field input{
    width: 250px;
    height: 100%;
    /* border: 0px; */
    border-width: 0 0 1px;
    
}


.update-password-button {
    background-color: white;
    color: grey;
    border-radius: 7px;
    height: 30px;
    width: 150px;
    /* font-size: 10px; */
}

.update-password-button:hover {
    color: black;
}

.update-namn-button {
    background-color: white;
    color: grey;
    /* height: 3vh; */
    font-size: 12px;
    border-radius: 7px;
    margin-right: 4px;
    margin-left: 7px;
    width: 60px;
    /* border-width: 1px; */
}
.update-namn-button:hover {
    color: black;
}

.log-out-btn{
    background-color: rgb(255, 219, 219);
    color: grey;
    border-radius: 7px;
    height: 30px;
    width: 100px;
    margin-right: 7px;
}
.log-out-btn:hover{
    color: black; 
}

.confirm-message {
    margin-top: 10px;
    color: rgb(1, 54, 1);
    font-weight: 500;
    font-size:  20px;
    background-color: rgb(148, 241, 148);
    width: 100%;
    text-align: center;
}

.error-message {
    margin-top: 10px;
    color: rgb(54, 1, 1);
    font-weight: 500;
    font-size:  20px;
    background-color: rgb(241, 148, 148);
    width: 100%;
    text-align: center;
}

.form-change-password {
    /* color:red; */
    display: block;

}

.form-change-password label {
    display: block;
    font-size: 15px;
}

.new-password-input {
    width: 100%;
    display: block;
    border-width: 0 0 1px;
    border-color: grey;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.new-password-input:focus {
    border-color: rgba(38, 166, 154, 0.776);
    border-width: 0 0 2px;
}

.incorrect-password-input {
    color: red;
    border-color: red;
}

.incorrect-password-input:focus {
    border-color: red;
    
}