/*GENERAL CSS for boxes that show graphs and tables in the client view and for all components under those divs*/

@import "localFonts/customFont.css";

/*min_width ändras dynamiskt i main.js*/
.box-module {
  position: relative;
  border-radius: 16px;
  text-align: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* overflow-y: hidden; */
  /* overflow-x: hidden; */
  transition: all 0.2s ease-in-out;
  /* padding: 15px; */
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* A placeholder for box-module that is used when admin changes layout by drag and drop.*/

.box-module-placeholder {
  height: 100%;
}

/* under box-module-placeholder */
.admin-addVisual-button {
  position: relative;
  top: 30px;
  height: 30%;
  width: 30%;
}
/* under box-module in the admin version*/
.admin-span-button {
  position: relative;
  top: 30px;
}

/* under box-module */
.large-view-background {
  position: absolute;
  /* height: 100vh;
  width: 100vw; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
}

.large-view {
  width: 80vw;
  height: 80vh;
  position: relative;
  border-radius: 16px;
  text-align: center;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* overflow-y: hidden; */
  /* overflow-x: hidden; */
  transition: all 0.2s ease-in-out;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* under large-view-background*/
.large-view-button {
  max-width: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.large-view-button:hover {
  opacity: 0.5;
  cursor: pointer;
}

.close-large-view-button {
  max-width: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.close-large-view-button:hover {
  opacity: 0.5;
  cursor: pointer;
}

/*under large-view-background*/
.large-view {
}

/* under one_block_tableGraph - Highcharts - div som innehåller grafer . */
.heightContainer {
  height: 100%;
}

/*Highcharts/heightContainer - titel på visual object*/
.VisualTitle {
  font-weight: 400;
  font-size: 1.3em;
  padding: 0.4em;
  text-align: left;
  color: #2c3b58;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: nowrap;
  font-family: "Calibri Light";
}

.heightContainer .customer-table-container {
  /* position: relative; */
  width: 100%;
  height: calc(100% - 40px);
  padding-bottom: 20px;
  overflow: hidden;
}

.plot_area {
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0.5;
}

.table-tabulator-customer.tabulator .tabulator-row .tabulator-cell:hover {
  cursor: default;
  opacity: 0.5;
}

.table-tabulator-customer.tabulator
  .tabulator-row
  .tabulator-cell
  .tabulator-data-tree-control {
  border: 1px solid rgb(32, 188, 157) !important;
}

.rangeSelectorPanel {
  width: 100%;
  height: 10%;
  float: bottom;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.buttonSelection {
  width: 100%;
  margin-left: 10px;
  height: 100%;
  float: left;
  margin-top: 3px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.dateSelection {
  width: 50%;
  float: right;
  height: 100%;
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
}

/* .react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {

  margin: 0 0 0 0;
}

.react-datepicker-wrapper > .react-datepicker__input-container > input {
  width: 50%;
  font-size: 15px;
  border-style: none;
  border-bottom: 1px solid black;
  text-align: center;
}

@media screen and (max-width: 1270px) {
  .react-datepicker-wrapper > .react-datepicker__input-container > input {
    width: 50%;
    height: 22px;
    font-size: 12px;
    margin-right: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .react-datepicker-wrapper > .react-datepicker__input-container > input {
    width: 50%;
    height: 22px;
    font-size: 15px;
    margin-right: 30%;
  }
}

@media screen and (max-width: 670px) {
  .react-datepicker-wrapper > .react-datepicker__input-container > input {
    width: 50%;
    height: 22px;
    font-size: 12px;
    margin-right: 30%;
  }
} */

.buttonList {
  margin-bottom: 0px;
  font-size: small;
}

.buttonList > li {
  display: inline-block;
  padding-right: 13px;
  padding-left: 10px;
  padding-bottom: 4px;
  padding-top: 3px;
  font-size: 10px;
  border-right: 1px solid;
  border-color: grey;
}

/* Range selector buttons in large view */
.large-view li {
  font-size: 14px;
}

@media screen and (max-width: 1500px) {
  .buttonList > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 4px;
    padding-top: 3px;
    /* font-size: 10px; */
    border-right: 1px solid;
    border-color: grey;
  }
}

@media screen and (max-width: 1000px) {
  .buttonList > li {
    display: inline-block;
    padding-right: 7px;
    padding-left: 7px;
    padding-bottom: 4px;
    padding-top: 3px;
    font-size: 10px;
    border-right: 1px solid;
    border-color: grey;
  }
}

@media screen and (max-width: 670px) {
  .buttonList > li {
    display: inline-block;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 4px;
    padding-top: 3px;
    font-size: 10px;
    border-right: 1px solid;
    border-color: grey;
  }
}

.buttonList > li > a {
  color: black;
}

.selected {
  color: rgb(32, 188, 157);
  text-decoration: underline;
  text-underline-offset: 4px;
}
.buttonList > li {
  cursor: pointer;
}

.buttonList > li > a:active {
  color: green;
}

.activeButton {
  color: green;
}

/*PLOT AREA*/

/*under plot_area comes highCharts which is already styled.*/
/* .plot_area + div {

          } */

/* these are points in the performance graph, see FOM in the browser.*/

.performanceTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
}

@media screen and (max-width: 1500px) {
  .performanceTitle > .VisualTitle {
    margin: 0;
    height: 40px;
    font-size: 1.3em;
    padding: 0.4em;
    text-align: left;
    color: #2c3b58;
    text-transform: uppercase;
    letter-spacing: 1px;
    white-space: nowrap;
    font-family: "Calibri Light";
  }

  .performanceTitle
    > .jmfButton_container
    > .benchContainer
    > .dropdownJmfIndex {
    top: 5px;
    z-index: 1;
    font-family: inherit;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 0px;
    background: #fff !important;
    border-radius: 7px;
    margin-top: 2px;
    color: #2c3b58;
  }
}

@media screen and (max-width: 1250px) {
  .performanceTitle > .VisualTitle {
    margin: 0;
    height: 40px;
    font-size: 14px;
    padding: 0.4em;
    text-align: left;
    color: #2c3b58;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: "Calibri Light";
  }

  .performanceTitle
    > .jmfButton_container
    > .benchContainer
    > .dropdownJmfIndex {
    top: 5px;
    z-index: 1;
    font-family: inherit;
    font-size: 12px;
    text-transform: uppercase;
    border: 0px;
    background: #fff !important;
    border-radius: 7px;
    color: #2c3b58;
  }
  .VisualTitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .performanceTitle > .VisualTitle {
    margin: 0;
    height: 40px;
    font-size: 14px;
    padding: 0.4em;
    text-align: left;
    color: #2c3b58;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: "Calibri Light";
  }

  .VisualTitle {
    height: 40px;
    font-size: 14px;
    padding: 0.4em;
    text-align: left;
    color: #2c3b58;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: "Calibri Light";
  }

  .performanceTitle
    > .jmfButton_container
    > .benchContainer
    > .dropdownJmfIndex {
    top: 5px;
    z-index: 1;
    font-family: inherit;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 0px;
    background: #fff !important;
    border-radius: 7px;
    color: #2c3b58;
  }
}

.divisorDropDown {
  height: 1px !important;
  padding: 0 !important;
  background: black;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.benchDropDownTop {
  height: 50%;
  margin: 0;
  overflow-y: scroll;
  padding-top: 3px;
}

.fa-lg {
  font-size: 1.6em;
}

.benchmarkDropDownMainBenchInfo {
  float: left;
  width: 60%;
  margin-left: 5%;
  margin-bottom: 3px;
  text-align: left;
}

.benchmarkDropDownMainBenchInfoNumber {
  float: right;
  margin-right: 4%;
  margin-bottom: 3px;
}

.benchmarkDropDownMainElem {
  height: 100%;
  line-height: 30px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  line-height: 1em;
  margin-bottom: 5px;
  font-size: 11px;
}

.dropdown-container {
  position: relative;
  float: right;
}

.button-trigger-dropdown {
  width: 100px;
  height: 25px;
  position: absolute;
  right: 0px;
  z-index: 1;
}

.dropdown-content {
  display: none;
}

.dropdown-content-show {
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  width: 200px;
}

.dropdown-content-show div {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown-content-show div:hover {
  background-color: #ddd;
}
.dropdown-content-show .dropdown-item-selected,
.dropdown-content-show .dropdown-item-selected:hover {
  background-color: rgb(117, 117, 117);
}

.TopContainer {
  height: 200px;
  float: left;
  width: 50%;
}

.RequiredRateofReturn {
  height: 200px;

  float: right;
  width: 50%;
}

.selectpicker {
  width: 200px;
  /* height: 100px; */
  z-index: 100;
  position: absolute;
  /* right: 100px; */
  display: inline-block;
}

.button-trigger-dropdown {
  width: 100px;
  height: 25px;
  position: absolute;
  right: 0px;
  z-index: 1;
}

.dropdown-content {
  display: none;
}

.dropdown-content-show {
  display: block;
  position: absolute;
  right: 0px;
  top: 25px;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  width: 200px;
}

.dropdown-content-show div {
  color: black;
  padding: 5px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content-show div:hover {
  background-color: #ddd;
}
.dropdown-content-show .dropdown-item-selected,
.dropdown-content-show .dropdown-item-selected:hover {
  background-color: rgb(117, 117, 117);
}

.selectpicker {
  width: 200px;
  /* height: 100px; */
  z-index: 100;
  position: absolute;
  /* right: 100px; */
  display: inline-block;
}

/*"+benchmark" - knapp i performanceGraph för att visa benchmarks.*/
.dropdownJmfIndex {
  font-family: inherit;
  font-size: 1em;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-style: none;
  background: #fff !important;
  color: #2c3b58;
}
.dropdownJmfIndex:hover {
  cursor: pointer;
  color: grey;
  /* font-weight: 600; */
}

.mainBenchDropDownElem {
  margin-bottom: 3px;
}

button:focus {
  outline: none !important;
}

.dropDownListItem {
  height: 25px;
  font-size: 10px;
}

.benchDropDownbutton {
  /* vertical-align: -webkit-baseline-middle; */
  vertical-align: middle;
  color: rgb(32, 188, 157);
}
.benchDropDownbuttonActivated {
  /* vertical-align: -webkit-baseline-middle; */
  vertical-align: middle;
  color: red;
}

.benchDropDownbuttonActivated:hover {
  cursor: pointer;
}

.benchDropDownbutton:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

.benchDropDownText {
  float: left;
  width: 75%;
  height: 100%;
  word-break: break-all;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
  margin-left: 14px;
}

.dropDownbenchmarks {
  height: 200px;
  width: 250px;
  background-color: white;
  box-shadow: 0px 1px 3px 2px grey;
  opacity: 0.85;
}

.benchContainer {
  position: relative;
}

/*under performance-wrapper - Detta är för visual object table i table_nyckeltal.´js*/
/*padding-bottom skrivs in som inline css i tabel_nyckeltal.js då den är beroende av om det finns en titel eller inte*/
.minimalistBlack {
  color: #000;
  font-family: inherit;
  font-size: 110%;
  /* line-height: 1.5 !important; */
  font-weight: 300;
  width: 100%;
  height: 100%;
  margin-top: 0vh;
  border-collapse: collapse;
  border-top: 0px solid #000000;
  display: flex;
  flex-flow: column;
  overflow-y: hidden;
}

/*ligger under minimalistBlack*/
.categories_nyckeltal {
  overflow: hidden;
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
}

/*under categories_nyckeltal*/
.TableCategories {
  text-transform: uppercase;
  letter-spacing: 0.8px;
  border: 0px solid #000000;
}

/*under categories_nyckeltal*/
/*Första kategorin - alltså den tomma rutan som ligger över namnen i tabellen.*/
/*.TableCategories:first-child {
			    			text-align: left;
			    			text-transform: uppercase;
			   				white-space: nowrap;
			   				width: 40%;
							margin-right: 0;
						}*/

/*ligger under minimalistic black*/
.tableBody {
  flex: 1 1 auto;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  table-layout: fixed;
  border-top: 2px solid #000000;
}

/*ligger under tableBody*/
.visiblerow {
  visibility: visible;
  position: relative;
  width: 100%;

  text-align: left;
  text-transform: uppercase;
}

/*ligger under tableBody*/
.invisiblerow {
  visibility: hidden;
  /*make the invisible rows alwyas stay at the top and not float outisde table*/
  position: absolute;
  top: 0px;
  width: 0px;
  height: 0px;
  margin: 0px;
  text-align: left;
  text-transform: uppercase;
}

/*another name for some of the rows, table_rowType1 should have a different backgroundcolor than rowType2.*/
.table_rowType1 {
  background-color: rgba(0, 0, 0, 0.05);
}

/*alla visible rows och invisible rows, Man bör kunna lägga mycket av kodensm ligger under dem i denna istället.*/
.tableBody tr:hover {
  background: #e1e1e1;
}
/*under visible och invisible rows - elementen i tabellen*/
.tableBody tr td {
  letter-spacing: 0.8px;
  border: 0px solid #000000;
}

/*don't think this is needed anymore.*/
/*.indent_5px {
								    width: 5px;
								    background: none;
								    display: inline-block;
								}*/

/*ligger under tableBody*/
/*display verkar inte behövas, sparar denna om jag upptckäker att något inte fungerar.*/
/*table tbody tr {
			 			   	width: 100%;
			    			display: block;
						}*/

/*Detta är invisible row och visible row men det fungerar ej att skriva dem i sin egen CSS referens eller
						döpa om till .invisiblerow, visible row och tableBody. */
table thead,
table tbody tr {
  display: table;
  table-layout: fixed;
}

/*under minimalisticBlack*/
.TableGraphfooter {
  border-top: 1px solid #000;
  height: 0px;
}
/*under footer, det är i denna man kan lägga till notiser till tabellen.*/
.TableGraphfooter td {
  border: 0px solid #000000;
}
/*alla kolumner under tableBody och Categories nyckeltal.*/
/*.tableBody tr, .categories_nyckeltal .TableCategories {
						color: #000;
			    		background: #FFF;
			    		font-size: 14px;

					}*/

/*under tableBody.visiblerow/invisiblerow samt under Categories_Nyckeltal (TableCategories) */
/*alla kolumner förutom den första som är namnen, alltså alla nyckeltal och namnen på nyckeltal.*/
table.minimalistBlack td + td,
table.minimalistBlack th + th {
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
}

/*Detta är både första kolumnen i footer, tableBody och tableCategories som är kolumnen med namnen på grupper och värdeppaper.*/
.minimalistBlack tr td:first-child,
.TableCategories:first-child {
  text-align: left;
  text-transform: uppercase;
  white-space: wrap;
  width: 40%;
  margin-right: 0;
}

/* smallButtonExpand och smallButtonEmpty belongs to table_nyckeltal under class "first_columkn_left" */
.smallButton,
.smallButtonExpand {
  background: none;
  height: 20pt;
  font-size: 12pt;
  padding: 0;
  width: 20pt;
  margin: 5px;
  border: 0px;
}

.smallButtonEmpty {
  background: none;
  height: 20pt;
  font-size: 12pt;
  padding: 0;
  width: 20pt;
  margin: 5px;
  border: 0px;
}

.smallButton,
.smallButtonExpand {
  border: 1px solid #a1a1a1;
  border-radius: 50%;
  text-align: center;
}

.smallButtonExpand {
  font-weight: bold;
}

.smallButton:hover,
.smallButtonExpand:hover {
  cursor: pointer;
}
