

@import 'localFonts/customFont.css';


.file-types-container{
    height: 90%;
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);

}


.file-box-container {
    width: 90%;
    height: calc(100% - 60px);

    /* overflow-x: hidden; */
    overflow-y: hidden;

    border-radius: 6px;
    margin: 30px;
    margin-left: 30px;
    min-width: 400px;
}

.file-box-container table {
    height: 70%;
    --rowHeight: 20px;
}

.file-box-container thead {
    width: 100%;
}
.file-box-container tbody {
    display: block;
    height: 70%;
    width: 100%;
    overflow-y: auto;
}

.file-box-container table tr {
    width: 100%;
}


.file-box-container table thead tr:first-child{
    border-bottom-width: 2px;
    border-bottom-color: #979797;
}

.file-box-container th {
    font-weight: normal;
}

.file-box-container table tr {
    height: var(--rowHeight);
}

.file-box-container table td {
    padding-top: 1px;
    padding-bottom: 1px;
}


.file-box-container h2 {
    color: #7F7E80;
    font-size: large;
}


.file-box-container img {
    cursor: pointer;
    height: var(--rowHeight);
}